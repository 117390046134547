import * as React from "react";

import Seo from "../components/seo";
import { ContentBox, ContentBg } from "../components/ui";

const NotFoundPage = () => (
  <ContentBg>
    <ContentBox>
      <Seo title="404: Not found" />
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </ContentBox>
  </ContentBg>
);

export default NotFoundPage;
